import React from "react";

interface FootnoteProps {
  author: string;
  footnoteNumber: string;
  href: string;
  source: string;
  title: string;
}

const Footnote = ({
  author,
  footnoteNumber,
  href ,
  source,
  title
}: FootnoteProps) => {
  return (
    <div
      className="text-sm tracking-normal my-4 flex flex-row text-gray-700"
    >
      <div>
        <sup className="text-gray-700">{footnoteNumber}&nbsp;</sup>
      </div>
      <div className="overflow-auto">
        <span>{author}&nbsp;</span>
        <span className="italic">{title}&nbsp;</span>
        <span>{source}</span>
        <a
          className="block mt-1 text-xs text-indigo-600 hover:underline"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {href}&nbsp;&nbsp;<sup><i className="fas fa-external-link-alt"/></sup>
        </a>
      </div>
    </div>
  )
}

export default Footnote;
