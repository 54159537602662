import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Table from "~/components/table-lesson-2";
import Footnote from "~/components/footnote/footnote";

const headerText = [
  "Type of website",
  "Accuracy rating"
];

const data = [
  ["Government", "high"],
  ["National organization", "high"],
  ["Educational", "medium"],
  ["News site", "medium"],
  ["Personal blog", "low"],
  ["Product review", "low"]
];

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={2}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 2</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={true}
              setFullScreen={setStudentFullScreen}
            >
              <p>In a recent study, investigators searched the web to find answers to health questions. They wanted to find out if health information on websites is accurate.* They looked at 1,300 websites.</p>
              <p>Click on each type of website to learn about the main reason for the accuracy rating.</p>

              <Table data={data} headerText={headerText} color="hs"/>

              <p>Other studies have found similar issues with the accuracy of information on the internet. Health professionals are concerned because many people do not realize that much of the health information on the internet is inaccurate or incomplete as they use websites when making health decisions.</p>

              <Footnote
                author="Chung, M., Oden, R. P., Joyner, B. L., Sims, A., and Moon, R. Y. (2012). Safe Infant Sleep Recommendations on the Internet: Let’s Google It."
                footnoteNumber="*"
                href="https://doi.org/10.1016/j.jpeds.2012.06.004"
                source="(6):1080-4. DOI:"
                title="Journal of Pediatrics, 161"
              />
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={true}
              setFullScreen={setTeacherFullScreen}
            >
              <p>In this step, students review the results of a study about accuracy of information on the internet. After students have seen the results, ask them if they are surprised by any of the data.</p>
              <p>Some students may be surprised to learn that so much inaccurate information is found on the internet. Even though there are differences in the degree of accuracy among the different types of sources, the researchers did not find any type of source to be completely accurate. Some of the inaccuracies are due to sites not being maintained or updated; other inaccuracies are related to not understanding or misinterpreting information. The results of this study are consistent with others that also have found significant inaccuracies on health-related websites.</p>
              <p>Hold a brief discussion about why problems with accuracy of information can be important to consider.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
