import React, { useState, Dispatch, SetStateAction } from "react";
import clsx from "clsx"

interface TableHeaderProps {
  className?: string;
  headerText: string[];
};

interface TableBodyProps {
  className?: string;
  color: string;
  data: string [][];
  description: number;
  setSelected: Dispatch<SetStateAction<number>>;
};

interface TableProps {
  color: string;
  data: string[][];
  headerText: string[];
};

const TableHeader = ({ headerText }: TableHeaderProps) => {
  return (
    <div className="grid grid-cols-2">
      {
        headerText.map((title: string, index: number) => {
          return (
            <div
              key={index}
              className="font-bold text-lg text-gray-700 w-auto p-4 rounded-tl rounded-tr lg:h-full"
            >
              {title}
            </div>
          );
        })
      }
    </div>
  );
};

const GovernmentDescription = () => {
  return (
    <p><strong className="text-darkBlue">Government:</strong> These websites are mostly accurate but sometimes provide inaccurate information because it is out of date.</p>
  );
};

const NationalOrganization = () => {
  return (
    <p><strong className="text-darkBlue">National organization:</strong> These websites are mostly accurate but sometimes provide inaccurate information because it is out of date.</p>
  );
};

const Educational = () => {
  return (
    <p><strong className="text-darkBlue">Educational:</strong> Perhaps it is surprising that educational websites contain a fair amount of inaccurate information. This is mainly because they often contain out-of-date information or users cannot get the information unless they pay for a subscription.</p>
  );
};

const NewsSite = () => {
  return (
    <p><strong className="text-darkBlue">News Site:</strong> In this study, national news coverage was accurate about half the time. However, the news stories often focused more on controversies rather than accepted facts. At times, the stories focused more on negative or tragic outcomes rather than accepted health practices or recommendations.</p>
  );
};

const PersonalBlog = () => {
  return (
    <p><strong className="text-darkBlue">Personal Blog:</strong> Personal blogs may contain useful information about coping with a medical condition. However, many patients miscommunicate medical information, either because they do not fully understand it or because they reject it.</p>
  );
};

const ProductReview = () => {
  return (
    <p><strong className="text-darkBlue">Product Review:</strong> Many product review sites do not focus on medical information. The medical information that is present is often not checked for accuracy.</p>
  );
};

const getDescription = (selected: number) => {
  const descriptions: (() => JSX.Element)[] = [GovernmentDescription, NationalOrganization, Educational, NewsSite, PersonalBlog, ProductReview];
  if (selected >= descriptions.length) {
    return null
  };

  const Tag: () => JSX.Element = descriptions[selected];

  return (
    <Tag />
  );
};

const TableBody = ({ data, setSelected, color, description }: TableBodyProps) => {
  return (
    <div>
      {
        data.map((row: string[], index: number) => {
          return (
            <button
              className= {clsx(
                "grid grid-cols-2 gap-0 w-full justify-items-start",
                  {
                    "bg-indigo-100 text-darkBlue font-bold": description == index  && color == "hs",
                    "even:bg-gray-100 text-gray-600 odd:bg-gray-200": description !== index,
                    "bg-purple-100 text-darkBlue font-bold": description == index  && color == "ms",
                  })}
              id={`${index}`}
              key={index}
              onClick={() => setSelected(index)}
            >
              {
                row.map((item: string, index: number) => {
                  return (
                    <div
                      id={`${index} ${item}`}
                      key={index}
                      className="px-4 py-2 h-full"
                    >
                      {item}
                    </div>
                  );
                })
              }
            </button>
          );
        })
      }
    </div>
  );
};

const Table = ({ headerText, data, color}: TableProps) => {
  const [selected, setSelected] = useState<number>(0);

  return (
    <div color={color} className={ color == "hs" ? "bg-indigo-200 shadow-md rounded" : "bg-purple-200 shadow-md rounded"}>
      <div>
        <TableHeader headerText={headerText}/>
        <TableBody data={data} setSelected={setSelected} color={color} description={selected} />
      </div>
      <div color={color} className={clsx(
                "p-4 bg-white border-t-4",
                  {
                    "border-indigo-200":  color == "hs",
                    "border-purple-200": color == "ms",
                  })}>
        {getDescription(selected)}
      </div>
    </div>
  );
};

export default Table;
